body {
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: 'Roboto Mono', monospace;
}

@media (min-width: 768px) {
  body {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.36);
  }

  ::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
    cursor: pointer;
  }
  ::-webkit-scrollbar-track {
    cursor: pointer;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background: rgba(0, 0, 0, 0.36);
    border-radius: 4px;
    cursor: pointer;
  }
}
